var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Detail Data Pelanggaran ")]),_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"nisn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Siswa","label-for":"nisn","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"nisn","reduce":function (nisn) { return nisn.nisn; },"placeholder":"Pilih Siswa","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listSiswa,"label":"name","disabled":""},model:{value:(_vm.dataStudent.nisn),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nisn", $$v)},expression:"dataStudent.nisn"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Tanggal","label-for":"date"}},[_c('validation-provider',{attrs:{"name":"Tanggal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal","type":"date","disabled":""},model:{value:(_vm.dataStudent.date),callback:function ($$v) {_vm.$set(_vm.dataStudent, "date", $$v)},expression:"dataStudent.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Masalah","label-for":"problem"}},[_c('validation-provider',{attrs:{"name":"Masalah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"problem","state":errors.length > 0 ? false : null,"placeholder":"Masalah","disabled":""},model:{value:(_vm.dataStudent.problem),callback:function ($$v) {_vm.$set(_vm.dataStudent, "problem", $$v)},expression:"dataStudent.problem"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Penanganan","label-for":"handling"}},[_c('validation-provider',{attrs:{"name":"Penanganan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"handling","state":errors.length > 0 ? false : null,"placeholder":"Penanganan","disabled":""},model:{value:(_vm.dataStudent.handling),callback:function ($$v) {_vm.$set(_vm.dataStudent, "handling", $$v)},expression:"dataStudent.handling"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"teacher_report_from"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Laporan Dari","label-for":"teacher_report_from","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"teacher_report_from","reduce":function (teacher_report_from) { return teacher_report_from.teacher_report_from; },"placeholder":"Pilih Guru","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.List_teacher_report_from,"label":"name","disabled":""},model:{value:(_vm.dataStudent.teacher_report_from),callback:function ($$v) {_vm.$set(_vm.dataStudent, "teacher_report_from", $$v)},expression:"dataStudent.teacher_report_from"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"teacher_who_handles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Guru Yang menangani","label-for":"teacher_who_handles","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"teacher_who_handles","reduce":function (teacher_who_handles) { return teacher_who_handles.teacher_who_handles; },"placeholder":"Pilih Guru","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.List_teacher_who_handles,"label":"name","disabled":""},model:{value:(_vm.dataStudent.teacher_who_handles),callback:function ($$v) {_vm.$set(_vm.dataStudent, "teacher_who_handles", $$v)},expression:"dataStudent.teacher_who_handles"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"info"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Back ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }