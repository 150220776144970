<template>
  <b-card>
    <h4 class="mb-0">
      Detail Data Pelanggaran
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="nisn" rules="required">
              <b-form-group label="Siswa" label-for="nisn" :state="errors.length > 0 ? false : null">
                <v-select id="nisn" v-model="dataStudent.nisn" :reduce="(nisn) => nisn.nisn" placeholder="Pilih Siswa"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listSiswa" label="name" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group label="Tanggal" label-for="date">
              <validation-provider #default="{ errors }" name="Tanggal" rules="required">
                <b-form-input id="date" v-model="dataStudent.date" :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal" type="date" disabled/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Masalah" label-for="problem">
              <validation-provider #default="{ errors }" name="Masalah" rules="required">
                <b-form-input id="problem" v-model="dataStudent.problem" :state="errors.length > 0 ? false : null"
                  placeholder="Masalah" disabled/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Penanganan" label-for="handling">
              <validation-provider #default="{ errors }" name="Penanganan" rules="required">
                <b-form-input id="handling" v-model="dataStudent.handling" :state="errors.length > 0 ? false : null"
                  placeholder="Penanganan" disabled/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>


            <validation-provider #default="{ errors }" name="teacher_report_from">
              <b-form-group label="Laporan Dari" label-for="teacher_report_from"
                :state="errors.length > 0 ? false : null">
                <v-select id="teacher_report_from" v-model="dataStudent.teacher_report_from"
                  :reduce="(teacher_report_from) => teacher_report_from.teacher_report_from" placeholder="Pilih Guru"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="List_teacher_report_from" label="name" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider #default="{ errors }" name="teacher_who_handles" rules="required">
                <b-form-group label="Guru Yang menangani" label-for="teacher_who_handles"
                  :state="errors.length > 0 ? false : null">
                  <v-select id="teacher_who_handles" v-model="dataStudent.teacher_who_handles"
                    :reduce="(teacher_who_handles) => teacher_who_handles.teacher_who_handles" placeholder="Pilih Guru"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="List_teacher_who_handles" label="name" disabled/>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="info" class="mr-1"
              @click="() => $router.go(-1)">
              Back
            </b-button>
            
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <b-container> -->

    <!-- </b-container> -->
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    listSiswa: [],
    List_teacher_who_handles: [],
    List_teacher_report_from: [],
    listGuru: [],
    dataCountries,
    dataProvinces,
    dataCities,
    dataDistrics,
    dataSubDistrics,
    required,
    name: '',
    dataStudent: {
      _method: 'PUT',
      nisn: '',
      date: '',
      problem: '',
      handling: '',
      teacher_who_handles: '',
      teacher_report_from: '',
    },
  }),
  computed: {

  },
  created() {
    this.getStudentDetail(),
      this.getsiswa(),
      this.getGuru()
  },
  methods: {
    async getsiswa() {
      try {
        const response = await this.$http.get('/students')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getGuru() {
      try {
        const response = await this.$http.get('/employees')
        this.listGuru = response.data.data;
        for (var i in this.listGuru) {
          var item = this.listGuru[i];

          this.List_teacher_who_handles.push({
            "teacher_who_handles": item.real_id,
            "name": item.name,
          });

          this.List_teacher_report_from.push({
            "teacher_report_from": item.real_id,
            "name": item.name,
          });
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/studentviolations/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.nisn = data.nisn
      this.dataStudent.problem = data.problem
      this.dataStudent.date = data.date.substr(0, 10)
      this.dataStudent.handling = data.handling
      this.dataStudent.teacher_who_handles = data.teacher_who_handles
      this.dataStudent.teacher_report_from = data.teacher_report_from
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            this.$http.put(`/studentviolations/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            // alertnya()
          } catch (err) {
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
